import { Container } from '@mui/system'
import React from 'react'
import SpotImg from '../assets/SpotDummyImg.png'

const Spot = () => {
  return (
    <>
    <Container>

        

    <img src={SpotImg} alt='spotimg'/>
    
    
    </Container>
    
    </>
  )
}

export default Spot